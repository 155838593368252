.headerWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 56px;
  color: var(--white);
  background-color: var(--black);
  padding: 0 150px;
}

.headingWrapper {
  font-weight: var(--fw-semi-bold);
  margin-right: calc(var(--pb-gutter-basic) * 3);
}

.storySlugWrapper {
  flex-grow: 1;
  padding-top: 23px;
  padding-left: 92px;
  padding-right: 50px;
}

.storySlugWrapper input {
  color: var(--white);
  padding-left: 23px;
  border-radius: 3px;
  background: var(--black);
}

.slugInputWrapper {
  position: relative;
  flex-grow: 1;
}

/* positioning the globe icon inside input field */
.globeIconWrapper {
  position: absolute;
  top: 29px;
  left: 96px;
  height: 16px;
  width: 16px;
}

.actionButtonsWrapper {
  display: flex;
  align-items: center;
}

.iconWrapper {
  margin: var(--pb-gutter-basic);
}

.activeIcon {
  background-color: #797979;
  border-radius: 50%;
  z-index: 100;
  padding: 4px 5px 3px;
}

.iframeWrapper {
  display: flex;
  justify-content: center;
}

.iframeWrapper iframe {
  border: none;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.16);
}

.iframeWrapperMobile {
  margin: calc(var(--pb-gutter-basic) * 4) 0;
}

.iframeWrapperDesktop {
  height: 100vh;
}

.sectionWrapper {
  position: relative;
  display: flex;
  align-items: center;
  width: 75%;
}

.previewTextHead {
  margin-right: calc(var(--pb-gutter-basic));
  color: var(--mono-3);
}

.previewTextValue {
  margin-right: calc(var(--pb-gutter-basic));
}

.dropdown {
  width: 396px;
}

.dropdown :global(.select-container) {
  height: 35px;
  margin-bottom: 0;
  background-color: black;
  color: white;
}

.dropdown :global(.react-select-container) {
  height: 15px;
}

.dropdown :global(.react-select__value-container),
.dropdown :global(.react-select__control),
.dropdown :global(.react-select__indicators),
.dropdown :global(.react-select__input),
.dropdown :global(.react-select__menu),
.dropdown :global(.react-select__single-value) {
  background-color: black;
  color: white;
}

.dropdown :global(.react-select__control--is-focused),
.dropdown :global(.react-select__control):hover {
  outline: none;
  border: 1px solid white;
  margin-bottom: 0;
  box-shadow: none;
}

.dropdown :global(.react-select__option):hover {
  background-color: var(--mono-4);
  opacity: 0.8;
}

.svgWrapper {
  line-height: 2px;
}

.loadingImage {
  margin-top: 20%;
  padding-bottom: var(--fs-xl);
}

.loadingTitle {
  font-size: var(--fs-xxl);
  font-weight: 700;
  justify-content: center;
  text-align: center;
  padding-bottom: var(--fs-l);
}

.loadingDesc {
  font-size: var(--fs-xl);
  font-weight: 450;
  text-align: center;
  color: #333;
}

.handsUp {
  width: var(--fs-xl);
  display: inline;
  position: absolute;
  margin-left: 5px;
}
