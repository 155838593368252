.colorPicker {
  margin-bottom: 8px;
  padding-bottom: 12px;
}
.label {
  font-size: var(--fs-s);
  color: var(--mono-5);
  font-weight: var(--fw-bold);
}

.colorContainer {
  width: var(--space-xl);
  height: var(--space-xl);
  border: solid 1px var(--mono-3);
  display: inline-block;
  position: relative;
  z-index: 1;
  border-radius: 50%;
  margin-right: var(--space-xxs);
}

.description {
  color: var(--mono-4);
  margin: 4px 0 0 0;
  font-size: var(--fs-s);
}

.visibleCheckImg {
  position: absolute;
  width: 24px;
  left: var(--space-xxs);
  top: 5px;
}
