.wrapper {
  padding-top: 10px;
}

.switchWrapper {
  display: flex;
  justify-content: space-between;
  margin: 10px 0 var(--space-l);
}

.content {
  font-size: var(--fs-s);
}

.wrapper :global(.textFieldCustomStyle) {
  margin-left: 0 !important;
  margin-right: var(--space-xs);
}

.wrapper :global(.closeIconWrapper) {
  width: 16px;
  height: 16px;
  padding: unset;
  background-color: unset;
  border: 0;
  outline: 0;
  cursor: pointer;
}

.wrapper :global(.chipCustomStyle) {
  margin: 0 var(--space-xs) var(--space-xs) 0;
  font-size: var(--fs-xs);
  line-height: 1.33;
  text-align: center;
  background-color: var(--mono-2);
  padding: var(--space-xxs) var(--space-xs);
}

.wrapper :global(.chipCustomStyle span) {
  padding-right: var(--space-xxs);
  text-transform: unset;
}

.domainsList {
  margin-top: -24px;
}

.toggleWrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: var(--space-m);
}

.description {
  font-weight: 400;
  font-size: 14px;
  padding-bottom: 8px;
}
