.wrapper {
  display: grid;
  grid-template-columns: 200px 1fr;
  overflow: scroll;
  min-height: 50vh;
}

.sidebar {
  padding-top: calc(var(--pb-gutter-basic) * 3);
  padding-right: calc(var(--pb-gutter-basic) * 3);
  padding-bottom: calc(var(--pb-gutter-basic) * 4);
  padding-left: 3px;
  border-right: solid 1px var(--mono-2);
}

.templatesHeading {
  font-size: var(--fs-m);
  font-weight: var(--fw-semi-bold);
  text-transform: capitalize;
}

.templatesDescription {
  font-size: var(--fs-s);
  padding-bottom: calc(var(--pb-gutter-basic) * 3);
}

.main {
  padding-top: calc(var(--pb-gutter-basic) * 3);
  padding-bottom: calc(var(--pb-gutter-basic) * 4);
  padding-left: calc(var(--pb-gutter-basic) * 12);
}
