.toggleContainer {
  padding-bottom: var(--space-xxs);
}

.quillWrapper {
  padding-bottom: var(--space-l);
}

.quillWrapper :global(.ql-toolbar) {
  border-radius: var(--space-xxs) var(--space-xxs) 0 0;
}

.quillWrapper :global(.ql-container) {
  border-radius: 0 0 var(--space-xxs) var(--space-xxs);
}

.colorPickerContainer {
  padding-top: 20px;
}

.overlay {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 34px;
  padding: 2px var(--space-xs);
  border: solid 1px var(--mono-3);
  outline: 0px !important;
  border-radius: 3px;
}

.selectWrapper {
  padding-top: var(--space-m);
}

.colorPickerWrapper {
  padding-top: var(--space-xs);
}

.labelWrapper {
  margin-bottom: var(--space-m);
}

.fieldLabel {
  margin-bottom: 20px;
}

.radioButtonWrapper {
  margin: var(--space-m) 0;
}

.radioButtonWrapper div label {
  text-transform: initial !important;
}

.description {
  color: #5f6978;
  font-weight: var(--fw-normal);
  font-size: var(--fs-s);
  margin: var(--space-s) 0;
}
