.redirect_item_wrapper {
  padding: var(--space-s) 0;
  border-bottom: 1px solid var(--mono-2);
}

.redirect_list_wrapper > :first-child {
  border-top: 1px solid var(--mono-2);
}

/*  override em context menu rtl styles  */
:global(.pb__global__html--rtl) .redirect_item_wrapper :global(.em__global__context-menu-box) {
  left: unset !important;
  right: 10px !important;
}

.read_item_title {
  display: inline-block;
  font-size: var(--fs-s);
  font-weight: var(--fw-semi-bold);
  color: var(--mono-5);
  flex: 0 0 148px;
}

.read_item_contents_wrapper {
  flex: 0 1 95%;
}

.read_item_content_wrapper {
  display: flex;
}

.read_item_content_wrapper:not(:last-child) {
  margin-bottom: var(--space-xs);
}

.read_item_wrapper {
  display: flex;
}

.read_item_context_menu_container {
  margin-top: var(--space-xxs);
}

.read_item_value {
  font-size: var(--fs-m);
  color: var(--mono-4);
  word-break: break-all;
}

.read_item_context_menu_container button {
  color: var(--mono-4);
}
