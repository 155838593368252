.checkboxWrapper {
  margin: 0 0 20px 0;
}

.customTextFieldWrapper,
.textFieldWrapper {
  display: grid;
  grid-column-gap: 12px;
}

.textFieldWrapper > :first-child {
  grid-column: 1/2;
  grid-row: 1/2;
}

.textFieldWrapper > :nth-child(2) {
  grid-column: 2/3;
  grid-row: 1/2;
}

.copyWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: var(--space-xs) auto var(--space-m) auto;
  font-size: var(--fs-s);
}

.copyButton span {
  margin: 0 0 0 var(--space-xs);
  color: #4860bc;
  font-size: var(--fs-s);
}

.copyButton {
  height: 32px;
  padding: 6px var(--space-s) 6px var(--space-s);
  border-radius: 3px;
  border: solid 1px #4860bc;
  display: inline-flex;
  background: transparent;
  cursor: pointer;
}

.customTextFieldWrapper > :first-child {
  grid-column: 1/3;
  grid-row: 1/2;
}
