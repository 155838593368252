.toggleContainer {
  padding-bottom: var(--space-xxs);
}

.quillWrapper {
  padding-bottom: var(--space-l);
}

.quillWrapper :global(.ql-toolbar) {
  border-radius: var(--space-xxs) var(--space-xxs) 0 0;
}

.quillWrapper :global(.ql-container) {
  border-radius: 0 0 var(--space-xxs) var(--space-xxs);
}

.colorPickerContainer {
  padding-top: 20px;
}

.overlay {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 34px;
  padding: 2px 8px;
  border: solid 1px var(--mono-3);
  outline: 0px !important;
  border-radius: 3px;
}

.selectWrapper {
  padding-top: 16px;
}

.colorPickerWrapper {
  padding-top: 8px;
}

.labelWrapper {
  margin-bottom: 16px;
}

.fieldLabel {
  margin-bottom: 20px;
}
