.inspectorWrapper {
  font-family: var(--brandType);
}

.btnWrapper button {
  margin-right: calc(var(--pb-gutter-basic) * 1.9);
}

.inspectorWrapper h3 {
  font-weight: var(--fw-bold) !important;
}

.inspectorWrapper > div > aside {
  font-family: var(--brandType);
  --inspectorShadow: 0 0 6px 0 rgb(255 255 255 / 10%), 0 2px 6px 0 rgb(255 255 255 / 10%),
    -2px -3px 6px 0 rgb(66 66 66 / 10%);

  header h3 {
    font-family: var(--brandType);
  }

  label {
    text-transform: capitalize;
    font-family: var(--brandType);
    font-weight: var(--z700);
    font-size: var(--fs-m);
  }

  header button {
    position: relative;
    left: 22px;
  }

  div > ul {
    font-weight: var(--z600);
    font-family: var(--brandType);

    li {
      color: var(--mono-5);
    }
  }

  div[role='button'] {
    line-height: normal;
  }

  div[role='region'] {
    border-bottom: 1px solid #f1f1f1;

    label {
      font-size: var(--fs-s);
    }
  }

  div > div > div > div:nth-child(2n) {
    label {
      font-size: var(--fs-s);
    }

    input,
    div {
      font-family: var(--brandType);
    }
  }
}

:global(.pb__global__html--rtl .em__global__inspector) {
  right: 0 !important;
  transform: translateX(100%) !important;
  left: initial !important;
}

:global(.pb__global__html--rtl .em__global__inspector__close) {
  margin-right: var(--space-s) !important;
  margin-left: 0px !important;
}

:global(.pb__global__html--rtl .em__global__inspector__secondary-action-btn) {
  margin-left: 0px !important;
  margin-right: var(--space-s) !important;
}

:global(.pb__global__html--rtl .em__global__inspector.em__global__inspector--is-active) {
  transform: translateX(0) !important;
}

:global(.pb__global__html--rtl) .inspectorWrapper *,
:global(.pb__global__html--rtl) .inspectorWrapper *::after,
:global(.pb__global__html--rtl) .inspectorWrapper *::before {
  direction: ltr;
}
.popupOverlay {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  width: 100vw;
  height: 150vh;
  z-index: var(--z1000);
  background-color: rgba(255, 255, 255, 0.8);
  font-family: var(--brandType);
}

.popupWrapper {
  width: 430px;
  position: absolute;
  top: 18%;
  left: 37%;
  padding: calc(var(--pb-gutter-basic) * 5) calc(var(--pb-gutter-basic) * 6);
  background-color: var(--white);
  box-shadow: 0 0 40px 0 rgb(0 0 0 / 7%);
  border-radius: var(--space-xs);
  border: 1px solid #eeeeee;
}

.closeButton {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  padding: var(--pb-gutter-basic);
}

.heading {
  font-size: 18px;
  font-weight: var(--fw-semi-bold);
  margin: 0 0 var(--pb-gutter-basic) 0;
}

.subheading {
  font-size: 14px;
  margin: 0 0 calc(var(--pb-gutter-basic) * 4) 0;
  color: var(--mono-4);
}

.saveMsgWrapper {
  position: fixed;
  left: 38%;
  top: 650px;
  min-width: 364px;
  max-width: 752px;
  height: 64px;
  border-radius: 3px;
  background-color: var(--mono-5);
  z-index: var(--z1000);
  padding: 22px calc(var(--pb-gutter-basic) * 4);
}

.saveMsgWrapper p {
  display: flex;
  justify-content: center;
  color: var(--white);
  font-size: 14px;
  position: relative;
  margin: 0;
}

body:global(.removeScroll) {
  overflow: hidden !important;
}

.inspectorWrapper input[type='checkbox'] {
  padding: 0;
}
