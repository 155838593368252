.wrapper {
  background-color: var(--mono-1);
  padding: var(--space-m);
  margin-bottom: var(--space-m);
  border: 1px solid var(--brand-1);
}

.title {
  font-size: var(--fs-m);
  font-weight: var(--fw-bold);
}

.links_container {
  display: flex;
  justify-content: space-between;
}

.links_container > div {
  width: 50%;
}

:global(.edit_redirect_save_button) {
  margin-right: var(--space-xs);
}

.links_container :first-child {
  margin-right: var(--space-s);
}

.button_container {
  display: flex;
  justify-content: flex-end;
}

.error-message {
  font-size: var(--fs-xs);
  font-weight: var(--fw-normal);
  color: var(--danger);
  margin-top: var(--space-xs);
  margin-right: var(--space-s);
}
