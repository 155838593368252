.breakingNews {
  position: relative;
  transition: opacity 0.3s;
  font-family: var(--primaryFont);
}

.buttonGroup {
  position: absolute;
  right: 0;
  opacity: 0;
  top: 0;
  bottom: 0;
  z-index: var(--z800);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  transition: opacity 0.3s;
}

.buttonGroup svg {
  position: relative;
  top: 2px;
}

.breakingNews:hover:after {
  position: absolute;
  left: 0;
  width: 100%;
  top: 0;
  height: 100%;
  content: '';
  background: var(--slighty-darker-blue);
  opacity: 0.2;
  z-index: var(--z600);
}

.breakingNews:hover .buttonGroup {
  opacity: 1;
}
