.checkboxWrapper {
  padding: calc(var(--pb-gutter-basic) * 2) 0;
}

.block-field-label {
  margin-bottom: 4px;
  font-size: var(--fs-m);
}

.visualstory-ad-radio-btns {
  margin: var(--space-l) 0 0 0;
}

.note {
  font-size: var(--fs-s);
  margin-bottom: 24px;
  color: var(--mono-4);
}

.adsense-client-id {
  margin: 0;
}

.admanager-wrapper {
  margin-bottom: 8px;
}

.adprovider-inputs {
  margin: 16px 0 0 0;
}

.insert-amp-custom-code {
  margin: var(--space-l) 0 0 0;
}
