.paywall-banners-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.banner-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.banner-name {
  border-radius: var(--arrow-spacing-xxs);
  background-color: rgb(230, 230, 230);
  padding: 0 var(--arrow-spacing-xs);
}
