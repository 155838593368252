.component {
  padding-top: var(--pb-gutter-basic);
  z-index: var(--z100);
}

.button {
  padding: var(--pb-gutter-basic);
  margin-right: calc(var(--pb-gutter-basic) * 2);
  outline: 0;
  border: 0;
  background: var(--white);
  cursor: pointer;
  border-radius: 2px;
  transition: all 0.1s;
  color: var(--mono-5);
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.16);
}

.button:hover {
  background: var(--brand-3);
  color: var(--white);
}
