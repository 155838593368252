.stylingWrapper {
  padding-top: calc(var(--pb-gutter-basic) * 4);
}

.demarcation-container {
  margin-bottom: 32px;
  div[data-test-id='checkbox-container'] {
    margin-bottom: 18px;
  }
}
