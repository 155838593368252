.popupOverlay {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  width: 100vw;
  height: 150vh;
  z-index: var(--z1000);
  background-color: rgba(255, 255, 255, 0.8);
}

.popupWrapper {
  width: 430px;
  height: 244px;
  position: absolute;
  top: 18%;
  left: 37%;
  padding: var(--space-xxl) var(--space-huge);
  background-color: var(--white);
  box-shadow: 0 0 40px 0 rgb(0 0 0 / 7%);
  border-radius: var(--space-xs);
  border: 1px solid #eeeeee;
}

.heading {
  font-size: var(--fs-l);
  font-weight: var(--fw-semi-bold);
  margin: 0 0 var(--pb-gutter-basic) 0;
}

.subheading {
  font-size: var(--fs-s);
  margin-bottom: var(--space-xl);
  color: var(--mono-4);
}

.closeButton {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  padding: var(--pb-gutter-basic);
}

.btnWrapper button {
  margin-right: calc(var(--pb-gutter-basic) * 1.9);
}
