.container {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 30px;
  z-index: var(--z1000);
  display: flex;
  justify-content: center;
}

.floatingBar {
  padding: 15px 20px;
  background-color: #333;
  color: var(--white);
  border-radius: 4px;
  opacity: 1;
  animation: fadeIn 2s linear;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 0;
  }
  15% {
    opacity: 1;
  }
}
