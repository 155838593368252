.wrapper {
  margin: 0;
  z-index: var(--z1000);
  position: relative;
}

.items {
  display: flex;
  flex-direction: column;
  list-style: none;
  color: var(--mono-5);
  background-color: var(--white);
  min-width: 210px;
}

.bottom .items {
  position: absolute;
  top: 45px;
  left: 120px;
}

.left .items {
  position: absolute;
  top: 0px;
  right: 48px;
}

.actionItem {
  display: flex;
  align-items: center;
  font-size: 14px;
  padding: 8px 16px;
  cursor: pointer;
}

.actionItem:hover {
  background: var(--mono-2);
}

.actionIcon:hover {
  color: blue;
}

.actionDropdownButton {
  text-align: center;
  padding: unset;
  color: var(--black);
  height: 40px;
  width: 40px;
  border: 0;
  border-radius: 3px;
  z-index: var(--z1000);
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.16);
}

.actionDropdownButton svg {
  position: relative;
  top: 2px;
}

.actionDropdownButton:hover {
  background-color: var(--brand-3);
}

.icons {
  margin-right: 8px;
  line-height: var(--lh-1);
}
