.wrapper {
  width: 100vw;
  background-color: var(--white);
}

:global(.pb__global__html--rtl) .wrapper *,
*::after,
*::before {
  direction: ltr;
}

.inner_wrapper {
  display: grid;
  margin: auto;
  max-width: var(--pb-container-width);
  position: relative;
  grid-template-columns: 200px 1fr;
  overflow: scroll;
}

.explanation_container {
  min-height: 100vh;
  padding: calc(var(--pb-gutter-basic) * 3 + var(--pb-gutter-basic) * 5) calc(var(--pb-gutter-basic) * 3) 0 3px;
  border-right: 1px solid var(--mono-2);
}

.explanation_title {
  font-size: var(--fs-m);
  font-weight: var(--fw-semi-bold);
  color: var(--mono-5);
  margin: 0;
}

.explanation_content {
  color: var(--mono-4);
}

.main_wrapper {
  padding: calc(var(--pb-gutter-basic) * 3 + var(--pb-gutter-basic) * 5) 0 0 calc(var(--pb-gutter-basic) * 12);
}

.heading {
  font-size: var(--fs-l);
  font-weight: var(--fw-bold);
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: var(--space-xxl);
}

.redirection_details {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.redirection_number {
  margin-right: var(--space-m);
}

:global(.redirect_dialog_cancel_button) {
  margin-right: var(--space-m);
}

.redirection_number_title {
  margin-right: var(--space-s);
}

.close_container {
  border: 0;
  border-radius: 100px;
  outline: 0;
  cursor: pointer;
  position: fixed;
  background: var(--white);
  padding: 0;
  margin: 0;
  right: calc(var(--pb-gutter-basic) * 2);
  top: calc(var(--pb-gutter-basic) * 2);
  color: var(--mono-4);
}

.header_button_container button:first-child {
  margin-right: var(--space-m);
}
