.container {
  margin: calc(var(--pb-gutter-basic) * 3);
  background-color: var(--white);
  padding: calc(var(--pb-gutter-basic) * 3);
  height: calc(100vh - (var(--pb-gutter-basic) * 3 * 2));
  display: flex;
  align-items: center;
  justify-content: center;
}

.wrapper {
  max-width: 800px;
}

.title {
  font-size: var(--fs-jumbo);
  font-weight: var(--fw-bold);
  text-align: center;
  color: #333333;
  margin: 0 0 calc(var(--pb-gutter-basic) * 3);
}

.subTitle {
  font-size: 32px;
  color: #333333;
  font-weight: var(--fw-bold);
  text-align: center;
}

.actionButtons {
  display: flex;
  justify-content: center;
  margin-top: calc(var(--pb-gutter-basic) * 6);
}

.buttonItem {
  margin: 0 var(--pb-gutter-basic);
}

.description {
  font-size: var(--fs-m);
  text-align: center;
  line-height: var(--lh-4);
}

.highlight {
  color: #4860bc;
  text-transform: capitalize;
}
