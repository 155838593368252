.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: calc(var(--pb-gutter-basic) * 5) 0;
}

.customWidget {
  width: 728px;
  height: 90px;
  font-size: var(--fs-xs);
  background-color: var(--fallback-img);
  display: flex;
  justify-content: center;
  align-items: center;
}
