.label {
  font-size: var(--fs-s);
  font-weight: normal;
  color: var(--mono-5);
}

.description {
  color: var(--mono-4);
  margin: 4px 0 0 0;
  font-size: var(--fs-xs);
}

.image {
  width: 347px;
  height: 16px;
  margin: 12px 0 12px 24px;
}

.selectedImgWrapper {
  border: 1px solid var(--brand-3);
  margin: 8px 0;
}

.imageWrapper {
  border: 1px solid var(--mono-3);
  margin: 8px 0;
}
