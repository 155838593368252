.ssoContainer {
  font-family: var(--brandType);
  font-weight: normal;
  font-style: normal;
  padding: var(--space-l) 0;
  min-height: 100vh;
  background-color: var(--white);
}

.logoWrapper {
  display: flex;
  align-items: center;
  margin: 0 auto;
  max-width: 1140px;
}

.logo {
  height: 40px;
  width: fit-content;
}

.title {
  font-size: var(--fs-xl);
  font-weight: var(--z600);
  color: #576965;
  font-family: var(--brandType);
  letter-spacing: 2px;
  text-transform: uppercase;
  margin-left: var(--fs-xxs);
}

.contentWrapper {
  max-width: 1140px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 120px var(--fs-xxl);
  margin: auto;
  font-size: var(--fs-jumbo);
  font-family: var(--brandType);
  font-style: normal;
  font-weight: var(--z600);
}

.pageHeader {
  margin: 0 0 12px 0;
  text-align: center;
  font-weight: var(--z300);
  font-size: 52px;
}

.description {
  font-size: var(--fs-xxl);
  color: #060606c9;
  line-height: var(--lh-4);
  font-weight: var(--z100);
  text-align: center;
  max-width: 684px;
  margin: 12px 0 12px 0;
}

.signInButton {
  font-family: var(--brandType);
  text-align: center;
  padding: var(--space-m) 34px;
  background-color: #4860bc;
  color: var(--white);
  border: none;
  font-size: var(--fs-m);
  letter-spacing: 1.5px;
  cursor: pointer;
  font-weight: var(--z600);
  border-radius: var(--space-s);
}
