.logoList {
  display: flex;
  margin-bottom: 16px;
  border: solid 1px var(--mono-3);
  width: max-content;
  border-radius: 3px;
}

.description {
  color: var(--mono-4);
  margin: 4px 0 0 0;
  font-size: var(--fs-xs);
}

.logos {
  height: 48px;
  width: auto;
  margin: 8px;
}

.trashWrapper {
  display: flex;
  align-items: flex-start;
  margin: 8px;
}

.buttonWrapper {
  margin-bottom: 16px;
}

.deleteLogoWrapper {
  padding: 0;
}
