.label {
  font-size: var(--fs-s);
  font-weight: normal;
  color: var(--mono-5);
  margin-top: 24px;
}

.description {
  color: var(--mono-4);
  margin: 4px 0 0 0;
  font-size: var(--fs-s);
}

.selectedLogoWrapper {
  border: 1px solid var(--brand-3);
  border-radius: 3px;
  margin: 8px 0;
  width: max-content;
  padding: 8px;
}

.logoWrapper {
  border: 1px solid var(--mono-3);
  border-radius: 3px;
  margin: 8px 0;
  padding: 8px;
  width: max-content;
}

.imageWrapper {
  height: 48px;
  width: auto;
}
