.componentWrapper {
  padding: 0 var(--space-m);
  max-width: 1172px;
  margin: 0 auto;
  font-family: var(--primaryFont);
  width: 100%;
}

:global(.file-download) {
  pointer-events: none !important;
}

.componentWrapper :global(.arrow-component.full-width-with-padding) {
  /*To avoid background-color being applied to the complete container in Page-Builder */
  margin-left: calc(-49.2vw + 50%);
  margin-right: calc(-49.2vw + 50%);
}

input[type='search']::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

.subscriptionWrapper {
  padding: 0 var(--space-m);
  margin: 0 auto;
  font-family: var(--primaryFont);
  width: 100%;
}
