.tabwrapper {
  top: calc((var(--pb-gutter-basic) * 10));
  background: var(--white);
  z-index: var(--z900);
}

.tabwrapper .tabs {
  position: sticky;
  z-index: var(--z900);
  top: 0;
  background: var(--white);
  border-bottom: 1px solid var(--mono-3);
  padding-top: calc((var(--pb-gutter-basic) * 2));
}
:global(.pb__global__html--rtl) .tabs,
:global(.pb__global__html--rtl) .tabs *,
:global(.pb__global__html--rtl) .tabs *::after,
:global(.pb__global__html--rtl) .tabs *::before {
  direction: ltr;
}

.tabs > ul {
  width: 400px;
}
