.btn {
  border: 2px solid gray;
  border-radius: 8px;
  color: gray;
  font-size: var(--fs-xl);
  font-weight: bold;
  padding: var(--space-xs) 20px;
}

.previewWrapper {
  display: flex;
}

.previewBtn {
  width: 100%;
  height: 32px;
  margin: 0 var(--space-xs) var(--space-xs) 0;
  padding: var(--space-xxs) var(--space-xxs) var(--space-xs);
  border-radius: 3px;
  border: solid 1px #c6ccd5;
  background-color: #fff;
  font-size: var(--fs-m);
}

.radioWrapper {
  margin-bottom: var(--space-m);
}

.uploader {
  color: transparent;
  padding-left: 0;
}

.loader {
  margin-bottom: 8px;
  color: gray;
}

.trashBtn {
  position: relative;
  top: 2px;
}

.trashBtn:hover {
  color: red;
}

.title {
  font-size: var(--fs-m);
  font-weight: var(--fw-bold);
  margin-right: 4px;
}

.heading {
  margin-bottom: var(--space-m);
  display: flex;
  align-items: center;
}

.checkboxWrapper {
  margin: var(--space-m) 0;
}
.buttonWrapper {
  position: relative;
  overflow: hidden;
  height: var(--space-xl);
  padding: 6px var(--space-m);
  border-radius: 3px;
  border: solid 1px #132437;
  font-size: var(--fs-s);
  background-color: #fff;
  color: #132437;
  margin: var(--space-xs) 0 18px;
}

.buttonWrapper input[type='file'] {
  cursor: pointer;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}
