.wrapper {
  display: flex;
  align-items: center;
  padding-left: var(--space-m);

  font-size: var(--fs-m);
  font-weight: 500;
  color: var(--mono-5);
}

.switchInput {
  display: none;
}

.switchLabel {
  position: relative;
  display: inline-block;
  width: 34px;
  cursor: pointer;
  padding-left: var(--space-s);
}

.switchLabel:before,
.switchLabel:after {
  content: '';
  position: absolute;
  margin: 0;
  outline: 0;
  top: 50%;
  -ms-transform: translate(0, -50%);
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  letter-spacing: 1.6px;
}
.switchLabel:before {
  content: 'LIGHT';
  padding: var(--space-xxs) 6px var(--space-xxs) 30px;
  border-radius: 11px;
  background-color: #f8e71c;
  font-size: var(--fs-xxs);
  font-weight: var(--fw-semi-bold);
  color: #313131;
}
.switchLabel:after {
  content: '☀';
  color: var(--white);
  width: 28px;
  height: 27px;
  padding: var(--space-xxxs) 6px var(--space-xxxs) 6px;
  border-radius: 13.5px;
  background-color: #f5a623;
}

.switchInput:checked + .switchLabel:before {
  content: 'DARK';
  padding: var(--space-xxs) 28px var(--space-xxs) 6px;
  font-size: var(--fs-xxs);
  border-radius: 11px;
  font-weight: var(--fw-semi-bold);
  color: var(--white);
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.16);
  background-color: #5f6978;
}
.switchInput:checked + .switchLabel:after {
  content: '☾';
  color: var(--white);
  width: 28px;
  height: 27px;
  padding: var(--space-xxxs) 6px var(--space-xxxs) 9px;
  border-radius: 13.5px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1);
  background-color: black;
  -ms-transform: translate(135%, -50%);
  -webkit-transform: translate(135%, -50%);
  transform: translate(155%, -50%);
}

:global(.wrapToolTip) {
  white-space: unset !important;
  width: 300px;
}
.iconWrapper {
  padding-left: 60px;
}
