.tagsGeneralSettingsWrapper {
  padding: 85px 150px;
  display: grid;
  grid-template-columns: 1fr 4fr;
  position: relative;
  background-color: var(--white);
}

:global(.pb__global__html--rtl) .tagsGeneralSettingsWrapper {
  direction: ltr;
}
.tagsGeneralSettingsWrapper input {
  min-height: 112px;
  border-radius: 3px;
}

.copyWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: var(--fs-s);
  font-weight: var(--fw-bold);
}

.copyButton span {
  margin-left: var(--space-xs);
  color: #4860bc;
  font-size: var(--fs-s);
}

.copyButton {
  height: 32px;
  padding: 6px var(--space-s) 6px var(--space-s);
  border-radius: 3px;
  border: solid 1px #4860bc;
  display: inline-flex;
  background: transparent;
  cursor: pointer;
}

.tabs,
.activeTab {
  font-size: var(--fs-s);
  color: #353536;
  margin: var(--fs-xxl) var(--fs-xl) var(--fs-m) 0;
  line-height: var(--lh-5);
  cursor: pointer;
}

.activeTab {
  font-weight: var(--fw-bold);
  color: #353536;
}

.saveWrapper {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
}

.shortCodeTitle {
  font-size: var(--fs-s);
  font-weight: var(--fw-bold);
}

.shortCodeMsg {
  font-size: var(--fs-s);
  margin: var(--pb-gutter-basic) 0 24px;
}

.closeIcon {
  position: absolute;
  right: 24px;
  top: 24px;
  cursor: pointer;
}

.shortCodeTemplate {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 24px;
}
