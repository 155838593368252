.wrapper {
  position: relative;
  top: 0;
  width: 100%;
  background-color: var(--white);
  box-shadow: 0 0 0.4rem #ffffff69;
  border-bottom: 1px solid #00000017;
}

:global(.pb__global__html--rtl) .wrapper *,
:global(.pb__global__html--rtl) .wrapper *::after,
:global(.pb__global__html--rtl) .wrapper *::before {
  direction: ltr;
}

.container {
  width: var(--pb-container-width);
  margin: auto;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: calc(var(--pb-gutter-basic) * 1);
  font-family: var(--brandType);

  h3 {
    font-family: var(--brandType);
    font-weight: 500;
  }
}

.navigation {
  flex: 1;
  display: flex;
  justify-content: flex-start;
}

.sidebar {
  position: absolute;
  top: calc(100% + (var(--pb-gutter-basic) * 2));
  min-width: 200px;
  left: 0;
  background-color: var(--white);
  color: var(--mono-5);
  z-index: var(--inspectorLevel1);
}

.hideDropdown {
  height: 0;
  position: absolute;
  top: calc(100% + (var(--pb-gutter-basic) * 2));
  min-width: 200px;
  left: 0;
  background-color: var(--white);
  color: var(--mono-5);
  z-index: var(--inspectorLevel1);
}

.buttonWrapper {
  font-family: var(--brandType);
  margin: 0 calc(var(--pb-gutter-basic) * 1) 0 0;
  font-weight: 600;
}

.settingsWrapper {
  position: relative;
  display: flex;
  cursor: pointer;
  border-radius: 0.4rem;
  background-color: var(--white);
  justify-content: center;
  align-items: center;
  padding: var(--pb-gutter-basic);
  margin: 0 var(--pb-gutter-basic) 0 0;
}

.moreVerticalWrapper {
  margin-right: calc(var(--pb-gutter-basic) * 2);
}

.settingColor {
  padding: 8px;
  border-radius: 3px;
  background-color: var(--mono-2);
}

.iconWrapper {
  display: flex;
  align-items: center;
  position: relative;
}

.undo {
  margin-left: 2.4rem;
}

.redo {
  margin: 0 2.4rem;
}

.makeChange {
  margin-right: 2.4rem;
}

.previewIconWrapper {
  border-radius: 3px;
  padding: var(--pb-gutter-basic);
  margin: 0 calc(var(--pb-gutter-basic) * 1) 0 0;
}

.previewIconWrapper:hover,
.settingsWrapper:hover {
  transition: background-color 0.3s;
  background-color: var(--mono-2);
}

.previewIconWrapper a {
  display: flex;
  align-items: center;
}

.loaderWrapper div {
  width: 47px;
  height: 14px;
  margin: unset;
}

.divider {
  width: 1px;
  height: 24px;
  margin: 0 12px;
  background-color: rgba(0, 0, 0, 0.1);
}

.toastContainer {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 30px;
  z-index: var(--z1000);
  display: flex;
  justify-content: center;
}

.floatingBar {
  padding: 15px 20px;
  background-color: #333;
  color: var(--white);
  border-radius: 4px;
  opacity: 1;
  animation: fadeIn 2s linear;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 0;
  }
  15% {
    opacity: 1;
  }
}
