.label {
  font-size: var(--fs-s);
  font-weight: normal;
  color: var(--mono-5);
}

.description {
  font-size: var(--fs-s);
  color: var(--mono-4);
  margin: var(--space-xs) 0 var(--space-xxs) 0;
}

.colorPaletteWrapper button {
  padding: 0;
  color: var(--mono-4);
}

.colorPaletteWrapper {
  margin-bottom: var(--space-m);
}
