.wrapper {
  padding: var(--space-s) 0 0;
}

.info {
  font-size: var(--fs-s);
  color: var(--mono-4);
  line-height: var(--lh-4);
  margin: var(--space-xs) 0 var(--space-m);
}

.toggleWrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: var(--space-m);
}

.linkWrapper {
  text-decoration: underline;
}

.linkWrapper:hover {
  color: var(--brand-3);
}
