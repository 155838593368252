.line {
  border: 1px dashed rgba(0, 0, 0, 0.25);
}

.space8 {
  margin: var(--space-xs) 0 var(--space-l);
}

.space16 {
  margin: var(--space-m) 0 var(--space-l);
}

.space24 {
  margin: var(--space-l) 0;
}
