/* Use EM CSS variables which is available https://github.com/quintype/em/blob/master/src/global.css now  */
/* Add override and extra CSS variables here */
:root {
  /* Gutters. Design will follow a proper gutters */
  --pb-gutter-basic: 8px;
  /* hardcoding ahead Primary color for better UI*/
  --primaryColor: #333;

  /* Container-width */
  --pb-container-width: 1172px;

  /* PB Font */
  --brandType: 'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
    'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

/* Resets */
html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  margin: 0;
  padding: 0;
  font-family: var(--brandType);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: var(--mono-2);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* Some normalizing styles */
a {
  text-decoration: none;
  color: inherit;
}

/* reset em rtl syles  for  rtl dir */
.pb__global__html--rtl .em__global__dialog-wrapper *,
*::after,
*::before {
  direction: ltr;
}
