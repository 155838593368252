.secretModeWrapper {
  width: 600px;
  margin: auto;
  padding: calc(var(--pb-gutter-basic) * 5);
}

.accordionWrapper {
  margin-bottom: calc(var(--pb-gutter-basic) * 2);
}
.publish {
  margin-top: 20px;
}
