.wrapper {
  display: flex;
}

.label {
  margin-right: 8px;
}

.setDefault {
  background-color: #f0f1f5;
  padding: var(--arrow-spacing-xxs);
  font-size: var(--arrow-fs-tiny);
  border-radius: 50%;
  width: var(--arrow-spacing-20);
  height: var(--arrow-spacing-20);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-bottom: var(--arrow-spacing-20);
  cursor: pointer;
}

.content {
  display: flex;
  padding: var(--arrow-spacing-s) 0;
  align-items: center;
  gap: var(--arrow-spacing-s);
}

.tabList {
  list-style: none;
  display: flex;
}

.tabs {
  background-color: #f0f1f5;
  color: #5f6978;
  width: 26px;
  height: 26px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: var(--arrow-fs-s);
  cursor: pointer;
}

.active-tabs {
  display: none;
}
