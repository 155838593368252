.container {
  position: relative;
  display: flex;
  flex-direction: column;
}

.menuTrack {
  width: 200%;
  display: flex;
  transition: transform 0.3s;
}

.menuTrack.menuTrack--slideLeft {
  transform: translate(-50%, 0);
}

.label {
  font-size: var(--fs-m);
  font-weight: var(--fw-semi-bold);
  color: var(--mono-5);
  padding: 13px var(--space-m);
  margin: 0;
  display: flex;
  align-items: center;
  background-color: var(--white);
  border-bottom: 1px solid var(--mono-2);
}

.title {
  display: flex;
  align-items: center;
  padding: var(--space-m) 0 18px;
  margin: 0 0 0 var(--space-xs);
  font-size: var(--fs-m);
  font-family: var(--brandType);
  cursor: pointer;
}

.chevronIcon {
  margin: 6px 0 0 var(--space-s);
}

.menuWrapper {
  display: none;
  width: 140%;
  min-width: 270px;
  top: 100%;
  transition: transform 0.3s;
  position: absolute;
  overflow: hidden;
}

.popUpMenuWrapper {
  display: none;
  transition: transform 0.3s;
  position: fixed;
  overflow: hidden;
  width: 672px;
  top: 78px;
  bottom: 0;
  height: 100%;
  left: 0;
  background: #fff;
  box-shadow: 2px 2px 6px 0px rgba(0, 0, 0, 0.12);
}

.menuWrapper--open {
  display: block;
  z-index: var(--inspectorLevel1);
  max-height: calc(100vh - 80px);
}

.menuLeft,
.menuRight {
  flex: 1;
  flex-basis: 100%;
  margin: var(--pb-gutter-basic);
  height: fit-content;
}

.menuLeft {
  margin-right: 0;
  box-shadow: 0 0 var(--pb-gutter-basic) var(--mono-3);
}

.backArrow {
  width: 16px;
  height: 16px;
  margin-right: var(--pb-gutter-basic);
  cursor: pointer;
}

.menuItem--wrapper {
  padding: var(--pb-gutter-basic) 0 0 0;
  margin: 0;
  background-color: var(--white);
  overflow-y: scroll;
  max-height: 340px;
}

.menuItem {
  font-size: var(--fs-s);
  list-style: none;
  color: var(--mono-5);
  cursor: pointer;
  display: flex;
  transition: background-color 0.3s;
  align-items: center;
  text-transform: capitalize;
}

.stickyElement {
  padding-bottom: 0;
}

.stickyElement .menuItem {
  position: sticky;
  bottom: 0;
  background-color: var(--white);
}

.stickyElement .optionValue {
  border-top: 1px solid var(--mono-2);
  &:hover {
    background-color: var(--mono-2);
  }
}

.menuItem a,
.menuItem span {
  flex: 1;
  padding: var(--pb-gutter-basic) calc(var(--pb-gutter-basic) * 3) 11px calc(var(--pb-gutter-basic) * 2);
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.menuItem:hover {
  background-color: var(--mono-2);
}

.submenu {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: var(--mono-1);
  flex-basis: 50%;
  transition: transform 0.3s;
}

.submenu--active {
  transform: translate(-50%, 0);
}

.menuTrack--slideLeft > .menuLeft {
  box-shadow: none;
}

.menuTrack--slideLeft > .menuRight > .submenu {
  box-shadow: 0 0 var(--pb-gutter-basic) var(--mono-3);
}

.titleLabel {
  color: #797979;
  margin-right: var(--space-xxs);
}

.hide {
  display: none;
}

.asideWrapper {
  display: flex;
  height: 100%;
}

.titleWrapper {
  border-radius: 40px;
  background: var(--brand-3);
  padding: 6px 14px;
  height: fit-content;
  color: #fff;
  margin-bottom: 24px;
  font-size: 16px;
  font-weight: 700;
}

.titleContainer {
  background: rgba(48, 85, 241, 0.03);
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08);
  width: 212px;
  padding: 24px 32px 0 24px;
  flex-shrink: 0;
}

.submenuContainer {
  padding: 24px 24px 0 16px;
  color: #333;
  font-size: 14px;
  font-weight: 700;
  overflow-y: scroll;
  width: 100%;
}

.submenuWrapper {
  margin-bottom: 24px;
}

.imageWrapper {
  margin-top: 8px;
}

.imageWrapper svg {
  border-radius: 6px;
  border: 3px solid rgba(51, 51, 51, 0.04);
}

.currentLayout {
  position: relative;
}

.currentLayout svg {
  border: 2px solid #333;
}

.currentLayout :global(.custom-chip) {
  background-color: #000;
  color: #fff;
  position: absolute;
  right: 30px;
  top: 34px;
}
