.warningMessage {
  margin: var(--fs-m) 0;
}

.aheadNxtBanner {
  margin-bottom: var(--space-m);
}

.centreAlignChkboxWrapper {
  margin: 0 0 var(--fs-l) 0;
}
