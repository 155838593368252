.checkBoxWrapper {
  margin-bottom: var(--arrow-fs-xs);
}

.iconWrapper {
  display: flex;
}

.image {
  width: var(--arrow-spacing-28);
  height: 34px;
  margin: 0 6px;
}

.selectedImgWrapper {
  border: 1px solid var(--brand-3);
  margin: 0 6px;
}

.imageWrapper {
  border: 1px solid var(--mono-3);
  margin: 0 6px;
}
