.wrapper {
  display: grid;
  grid-template-columns: 200px 1fr;
  overflow: scroll;
  min-height: 50vh;
}

.sidebar {
  padding-top: calc(var(--pb-gutter-basic) * 3);
  padding-right: calc(var(--pb-gutter-basic) * 3);
  padding-bottom: calc(var(--pb-gutter-basic) * 4);
  padding-left: 3px;
  border-right: solid 1px var(--mono-2);
}

.main {
  padding-top: calc(var(--pb-gutter-basic) * 3);
  padding-bottom: calc(var(--pb-gutter-basic) * 4);
  padding-left: calc(var(--pb-gutter-basic) * 12);
}

.label {
  display: block;
  font-size: var(--fs-s);
  margin-bottom: 4px;
}

.input {
  /* Will be moved to it's own component. */
  width: 170px;
  height: 32px;
  border-radius: 3px;
  border: solid 1px var(--mono-3);
  background-color: var(--white);
}

.wrapper .filters .addRow {
  color: var(--white);
  background-color: var(--brand-4);
}

.wrapper .filters .addRow:hover {
  background: var(--brand-3);
}

.buttonWrapper {
  position: relative;
}

:global(.pb__global__html--rtl) .buttonWrapper *,
:global(.pb__global__html--rtl) .buttonWrapper *::after,
:global(.pb__global__html--rtl) .buttonWrapper *::before {
  direction: ltr;
}

.button {
  display: flex;
  align-items: center;
  color: var(--mono-4);
  font-size: var(--fs-s);
  width: 100%;
  justify-content: center;
  position: relative;
  height: 32px;
}

.buttonWrapper:hover .button {
  opacity: 1;
}

.button.showBtn {
  opacity: 1;
}

.button.topBorder {
  border-top: 1px dashed var(--mono-3);
  top: -12px;
  bottom: unset;
}

.button.bottomBorder {
  border-bottom: 1px dashed var(--mono-3);
  bottom: -12px;
  top: unset;
}

.dashedLine {
  border-top: 1px dashed var(--mono-3);
  position: absolute;
  top: calc(var(--pb-gutter-basic) * 2);
  width: 100%;
}

.buttonWrapper:hover .dashedLine {
  border-top: 1px dashed var(--brand-3);
}

.heading {
  font-size: 16px;
  font-weight: 600;
  color: var(--mono-5);
}

.description {
  font-size: 14px;
  line-height: 1.43;
  color: var(--mono-5);
}

.chooseLayout {
  font-size: 14px;
  margin-bottom: 8px;
  color: var(--mono-5);
}

.switchWrapper {
  display: flex;
  justify-content: space-between;
  margin-top: calc(var(--pb-gutter-basic) * 2);
}

.labelBold {
  font-size: var(--fs-s);
  font-weight: var(--fw-bold);
}
