.components {
  display: flex;
  /* gutter size offset */
  width: auto;
  margin-left: calc(var(--pb-gutter-basic) * -3);
}

.componentsColoumn {
  padding-left: calc(var(--pb-gutter-basic) * 3);
}

.componentName {
  color: var(--mono-5);
  margin-bottom: calc(var(--pb-gutter-basic));
  font-size: var(--fs-s);
  font-weight: var(--fw-semi-bold);
}

.componentImgWrapper {
  border: 1px solid var(--mono-2);
  padding: calc(var(--pb-gutter-basic) * 2);
  position: relative;
}

.component {
  position: relative;
  margin-bottom: calc(var(--pb-gutter-basic) * 3);
}

.component:hover .componentImgWrapper {
  border: 1px solid var(--brand-3);
  cursor: pointer;
}

.componentImgWrapper.selected {
  border: 1px solid var(--brand-3);
}

.optimisedIcon {
  position: absolute;
  width: 24px;
  height: 24px;
  right: 4px;
  bottom: 4px;
}
