.checkBoxWrapper {
  margin-bottom: 16px;
}

.iconWrapper {
  display: flex;
  margin: 10px 0;
}

.image {
  width: 30px;
  height: 30px;
  margin: 0 6px;
}

.selectedImgWrapper {
  border: 1px solid var(--brand-3);
  margin: 0 6px;
}

.imageWrapper {
  border: 1px solid var(--mono-3);
  margin: 0 6px;
}
