.saveMsgWrapper {
  position: fixed;
  left: 38%;
  bottom: 20px;
  min-width: 364px;
  max-width: 752px;
  height: 64px;
  border-radius: 3px;
  background-color: var(--mono-5);
  z-index: var(--z1000);
  padding: 22px var(--space-xl);
}

.saveMsgWrapper p {
  display: flex;
  justify-content: center;
  color: var(--white);
  font-size: var(--fs-s);
  position: relative;
  margin: 0;
}
