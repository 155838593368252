.button_container {
  display: flex;
  justify-content: flex-end;
}

.button_container > button:first-child {
  margin-right: var(--space-m);
}

.title {
  font-size: var(--fs-l);
  font-weight: var(--fw-bold);
}

.content {
  line-height: var(--lh-4);
  color: var(--mono-4);
}
