.quillWrapper {
  padding-bottom: var(--space-l);
}

.hintText {
  font-size: var(--arrow-fs-tiny);
  color: var(--mono-4);
  margin: 4px 0 calc(var(--pb-gutter-basic) * 2);
}

.shortCode {
  padding: var(--space-xxxs) var(--space-xxs);
  margin-right: 6px;
  border-radius: var(--space-xxxs);
  background-color: #5f6978;
  color: #f9f9f9;
  font-size: var(--fs-xxs);
  font-weight: var(--fw-semi-bold);
  cursor: copy;
}

.shortCodeWrapper {
  margin-top: var(--arrow-spacing-m);
}

.meterOnAnonymous {
  margin-top: var(--arrow-spacing-s);
}

.radioToggle {
  margin-bottom: var(--arrow-spacing-m);
}

:global(.ql-editor.ql-blank::before) {
  content: none;
}

:global(.ql-editor.ql-blank::after) {
  color: var(--arrow-c-mono5);
  content: attr(data-placeholder);
  font-style: normal;
  pointer-events: none;
  display: block;
  margin-top: -1.42em;
}
