.checkbox-wrapper {
  margin: var(--space-xxs) 0 var(--space-l);
}

.text {
  font-size: var(--fs-s);
  color: var(--mono-4);
  line-height: var(--lh-4);
  margin: var(--space-xs) 0 var(--space-m);
}

.wide-textfield {
  margin: var(--space-xs) 0 0;
}

.wide-textfield input {
  margin-bottom: 0;
}

.field-lable {
  margin-top: var(--space-l);
}

.button-text-wrapper {
  margin-top: var(--space-xs);
}
