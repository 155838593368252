.label {
  font-size: 14px;
  font-weight: normal;
}

.description {
  font-size: 12px;
  color: var(--mono-4);
}

.socialLinkWrapper {
  list-style-type: none;
  padding: 0;
  background-color: var(--white);
  position: absolute;
  z-index: 9;
  box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.2);
  width: 100%;
}

.socialLinkInnerWrapper:first-child {
  margin-top: var(--pb-gutter-basic);
}

.socialLinkInnerWrapper:last-child {
  margin-bottom: var(--pb-gutter-basic);
}

.socialLinkWrapper .socialLinkInnerWrapper:hover {
  background-color: #f0f0f0;
}

.wrapperSvg {
  position: relative;
  z-index: 10;
  border-radius: 2px;
  border: solid 1px var(--gray-lightest);
  display: flex;
  justify-content: center;
}

.chevron {
  position: absolute;
  right: 16px;
  top: 10px;
}

.wrapper {
  position: relative;
}

.wrapper .socialLinkDescription {
  font-size: 12px;
  color: var(--mono-4);
  margin-top: calc(var(--pb-gutter-basic) / 2);
}
