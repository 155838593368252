.checkboxWrapper {
  margin: 16px 0 24px 0;
}

.hintWrapper {
  font-size: var(--fs-xs);
  color: var(--mono-4);
  margin-top: -20px;
}

.docWrapper {
  text-decoration: underline;
}

.docWrapper:hover {
  color: var(--brand-3);
}
