:global(.pb__global__html--rtl .em__global__left-icon-checkmark) {
  padding-left: 3px !important;
  padding-right: 0px !important;
}

:global(.pb__global__html--rtl .em__global__right-icon-checkmark) {
  padding-left: 11px !important;
  padding-right: 0px !important;
}

:global(.pb__global__html--rtl .em__global__left-icon-checkbox-input) {
  margin: 0 var(--space-xs) 0 0 !important;
}

:global(.pb__global__html--rtl .em__global__right-icon-checkbox-input) {
  margin: 0 0 0 var(--space-xs) !important;
}

:global(.pb__global__html--rtl .em__global__radio-option) {
  margin-right: var(--space-xs) !important;
  margin-left: 0 !important;
}
