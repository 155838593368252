.checkboxWrapper > * {
  margin: var(--space-m) 0 var(--space-l) 0;
}

.tooltipWrapper {
  display: flex;
  height: 20px;
}

.tooltipWrapper > * {
  margin-right: var(--space-xs);
}
