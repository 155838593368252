.label {
  font-size: var(--fs-s);
  font-weight: normal;
  color: var(--mono-5);
}

.image {
  width: auto;
  height: 20px;
  margin: calc(var(--pb-gutter-basic)) calc(var(--pb-gutter-basic) * 3);
}

.selectedImgWrapper {
  border: 1px solid var(--brand-3);
  margin: calc(var(--pb-gutter-basic)) 0;
  margin-right: calc(var(--pb-gutter-basic) * 3);
}

.imageWrapper {
  border: 1px solid var(--mono-3);
  margin: calc(var(--pb-gutter-basic)) 0;
  margin-right: calc(var(--pb-gutter-basic) * 3);
}

.sectionTagWrapper {
  display: flex;
  flex-wrap: wrap;
}

.description {
  color: var(--mono-4);
  font-size: var(--fs-xs);
}
