.iconWrapper {
  display: flex;
  margin-bottom: var(--space-l);
}

.image {
  width: var(--arrow-spacing-28);
  height: 34px;
}

.selectedImgWrapper {
  border: 1px solid var(--brand-3);
  margin-right: var(--space-xs);
  padding: 6px 10px;
}

.imageWrapper {
  border: 1px solid var(--mono-3);
  margin-right: var(--space-xs);
  padding: 6px 10px;
}
