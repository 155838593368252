.toggleButtonContainer {
  display: flex;
  z-index: var(--z1);
  justify-content: space-between;
  margin: 8px 0;
}

.labelContainer {
  margin-bottom: unset;
  font-size: var(--fs-s);
  font-weight: normal !important;
}
