.adStyleWrapper {
  background-color: var(--mono-3);
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
}
.widgetStyleWrapper {
  background-color: var(--mono-3);
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  width: 300px;
  height: 300px;
  font-size: var(--fs-s);
}

.description {
  color: var(--mono-4);
  margin: 4px 0 0 0;
  font-size: var(--fs-xs);
}

.warningMessage {
  margin: var(--fs-m) 0;
}
