.wrapper {
  margin: 0;
  cursor: pointer;
  padding: 0;
  box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.2);
  min-width: 270px;
}

.items {
  padding: 8px 0;
  margin: 0;
  list-style: none;
}

.hideDropdown {
  padding: 0;
  list-style: none;
}

.label {
  font-size: 16px;
  font-weight: 600;
  line-height: 1.38;
  color: var(--mono-5);
  padding: 13px 16px;
  margin: 0;
  display: flex;
  align-items: center;
  background-color: var(--white);
  border-bottom: 1px solid var(--mono-2);
}

.wrapper .list {
  padding: var(--pb-gutter-basic) calc(var(--pb-gutter-basic) * 3) 11px calc(var(--pb-gutter-basic) * 2);
  font-size: var(--fs-s);
}

.wrapper .list:hover {
  background-color: var(--mono-2);
}

.panel {
  display: none;
}

.openPanel {
  display: block;
}

.deleteLabel {
  font-weight: var(--fw-semi-bold);
}

.deleteWrapper > p {
  font-size: var(--fs-s);
}
