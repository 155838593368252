.wrapper * {
  color: var(--textColor);
}

.wrapper :global(.auth--text-color),
.wrapper :global(.edit-wrapper) svg path {
  color: var(--loginButtonColor);
}

.wrapper h1 {
  margin: 10px 0 !important;
  padding: 2px !important;
  font-weight: 700;
}

.overlay {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 2;
}

.wrapper :global(.auth--wrapper) {
  z-index: 1;
}

.wrapper :global(.auth--wrapper) h1,
.wrapper :global(.auth--wrapper) h1 * {
  font-weight: 700;
}
