.wrapper {
  position: relative;
}

.wrapper :global(.wrapToolTip) {
  position: absolute !important;
  left: -150px !important;
  top: 24px !important;
  padding: var(--space-xs) var(--space-s);
}
