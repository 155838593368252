.headersWrapper {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  justify-content: center;
}

.headersComponents {
  grid-column: 3/11;
}

.headersComponent {
  margin-bottom: calc(var(--pb-gutter-basic) * 3);
}

.headersImgWrapper {
  border: 1px solid var(--mono-2);
  padding: calc(var(--pb-gutter-basic) * 2);
}

.headersImgWrapper img {
  margin: auto;
  width: unset;
}

.headersComponent:hover .headersImgWrapper {
  border: 1px solid var(--brand-3);
  cursor: pointer;
}
