.text {
  font-size: var(--fs-s);
  color: var(--mono-4);
  margin: 0;
}

.radioWrapper,
.checkboxWrapper {
  margin: var(--space-m) 0 var(--space-l) 0;
}

.slotSetting {
  display: flex;
  justify-content: space-between;
}

.slotSetting :global(.select-container) {
  flex-basis: calc(50% - 8px);
}

.radioButtonWrapper,
.addSlotButton {
  margin: var(--space-m) 0;
}

.space {
  margin: var(--space-xs);
}

.aheadNxtBanner {
  margin-bottom: var(--space-m);
  font-weight: var(--fw-bold);
}

.warningMessage {
  margin: var(--fs-m) 0;
}

.centreAlignChkboxWrapper {
  margin: 0 0 var(--fs-l) 0;
}

.labelWrapper {
  margin-bottom: var(--space-m);
}

.quillWrapper {
  margin-bottom: var(--space-l);
}

.contentWrapper {
  color: #5f6978;
  font-size: var(--fs-xs);
  font-weight: var(--fw-normal);
  margin-bottom: var(--space-m);
}

.shortCodeWrapper {
  padding: var(--space-xxxs) var(--space-xxs);
  margin-right: 6px;
  border-radius: 2px;
  background: #5f6978;
  color: #f9f9f9;
  font-size: var(--fs-xxs);
  font-weight: var(--fw-semi-bold);
  text-transform: capitalize;
  cursor: copy;
}

.hintWrapper {
  margin-top: var(--space-xs);
  color: #5f6978;
  font-size: var(--fs-xs);
  font-weight: var(--fw-normal);
  line-height: normal;
}

.toggleContainer {
  margin-top: var(--fs-xl);
}

.toggleContainer :global(.toggle-button_labelContainer__2fo8S) {
  font-size: var(--fs-m);
}
