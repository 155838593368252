.header {
  position: relative;
  transition: opacity 0.3s;
  font-family: var(--primaryFont);
  margin-top: calc(var(--pb-gutter-basic) * 4);
}

.buttonGroup {
  position: absolute;
  right: 0;
  opacity: 0;
  top: 0;
  bottom: 0;
  z-index: var(--z900);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  transition: opacity 0.3s;
}

.buttonGroup svg {
  position: relative;
  top: 2px;
}

.header:hover:after {
  position: absolute;
  left: 0;
  width: 100%;
  top: 0;
  height: 100%;
  content: '';
  background: var(--slighty-darker-blue);
  opacity: 0.2;
  z-index: var(--z600);
}

.header:hover .buttonGroup {
  opacity: 1;
}

.header:global(.sticky) {
  position: fixed;
  top: calc((var(--pb-gutter-basic) * 11));
  width: calc(100% - (var(--pb-gutter-basic) * 4));
  margin: auto;
  z-index: 901;
}

:global(#header-preview a) {
  pointer-events: none;
}
