.pb-fullscreen-modal {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  will-change: transform;
  height: 100%;
  background: var(--white);
  z-index: var(--inspectorLevel1);
  overflow: auto;
  opacity: 1;
  transform: translateY(0);
  transition: all 0.3s;
  padding-top: calc(var(--pb-gutter-basic) * 5);
}

.pb-fullscreen-modal--hidden {
  opacity: 0;
  pointer-events: none;
  visibility: hidden;
  transform: translateY(50vh);
}

.pb-modal-content {
  max-width: var(--pb-container-width);
  margin: auto;
  position: relative;
}

.pb__global__html--rtl .pb-fullscreen-modal *,
.pb__global__html--rtl .pb-fullscreen-modal *::after,
.pb__global__html--rtl .pb-fullscreen-modal *::before {
  direction: ltr;
}
