.text {
  font-size: 14px;
  color: var(--mono-4);
  margin: 4px 0 calc(var(--pb-gutter-basic) * 2);
}

.collection {
  font-size: 14px;
  color: var(--mono-5);
  margin: calc(var(--pb-gutter-basic) * 2) 0 6px;
}
