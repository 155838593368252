.aheadPreview {
  position: absolute;
  top: calc((var(--pb-gutter-basic) * 11));
  left: calc((var(--pb-gutter-basic) * 2));
  overflow-y: overlay;
  overflow-x: hidden;
  width: calc(100% - (var(--pb-gutter-basic) * 4));
  height: 91vh;
  background: var(--white);
  /* Ahead basic CSS variables for body. Font families should be injected dynamically */
  --fontsize-larger: 24px;
  --fontsize-halfjumbo: 32px;
  --fontsize-largest: 29px;
  --fontsize-smaller: 11px;
  --fontsize-small: 13px;
  --fontsize-medium: 16px;
  --arrow-typeface-primary: var(--primaryFont);
  --arrow-typeface-secondary: var(--secondaryFont);
  font-family: var(--brandType);
  font-weight: 400;
  font-size: 100%;
  line-height: var(--bodyLineHeight);
  text-rendering: auto;
}

/* Clean this up */
.aheadPreview h1,
.aheadPreview h3,
.aheadPreview h4,
.aheadPreview h5,
.aheadPreview h6,
.aheadPreview p,
.aheadPreview ul,
.aheadPreview ol {
  font-weight: normal;
  margin: 0;
  margin-top: 0px;
  margin-right: 0px;
  margin-bottom: 0px;
  margin-left: 0px;
  padding: 0;
}
.aheadPreview h2 {
  font-weight: normal;
  margin: 0;
  margin-top: 0px;
  margin-right: 0px;
  margin-bottom: 0px;
  margin-left: 0px;
}

.svgContainer {
  height: 0;
  width: 0;
  visibility: hidden;
}
