.socialIconGroupWrapper {
  display: flex;
  justify-content: center;
}

.socialIconGroupWrapper .socialIconImage {
  width: 24px;
  height: 24px;
  margin: 8px;
}
