.adStyle {
  max-width: var(--pb-container-width);
  background-color: var(--fallback-img);
  display: flex;
  justify-content: center;
  align-items: center;
}

.adStyleWrapper {
  align-items: center;
  display: flex;
  justify-content: center;
  margin: calc(var(--pb-gutter-basic) * 5) 0;
}

.adProperties {
  font-size: var(--fs-xs);
}
