img {
  width: 100%;
  object-fit: cover;
}

.contextMenu {
  position: relative;
  color: var(--black);
  z-index: var(--z200);
}

.rowWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  transition: opacity 0.3s;
  padding-bottom: calc(var(--pb-gutter-basic) * 4);
}

.buttonGroup {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  margin: var(--pb-gutter-basic) 0 calc(var(--pb-gutter-basic) * 2) 0;
}

:global(.pb__global__html--rtl) .buttonGroup *,
:global(.pb__global__html--rtl) .buttonGroup *::after,
:global(.pb__global__html--rtl) .buttonGroup *::before {
  direction: ltr;
}

.buttonGroupChild {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  transition: opacity 0.3s;
  opacity: 0;
  z-index: var(--z100);
}

.buttonGroupChild svg {
  position: relative;
  top: 2px;
}

.buttonGroup:hover .hoverOverlay {
  width: 98vw;
  position: absolute;
  top: calc(-1 * var(--pb-gutter-basic));
  bottom: calc(-2 * var(--pb-gutter-basic));
  right: 0;
  background: var(--brand-3);
  opacity: 0.2;
  overflow: visible;
}

.buttonGroup:hover img {
  opacity: 1;
}

.buttonGroupChild:hover {
  opacity: 1;
}

.rowWrapper:hover .buttonGroupChild {
  opacity: 1;
}
.restTopAddRowWrapper,
.firstTopAddRowWrapper,
.bottomAddRowWrapper {
  width: 100%;
  position: absolute;
  opacity: 0;
  transition: opacity 0.3s;
}

.firstTopAddRowWrapper {
  top: 1px;
}
.restTopAddRowWrapper {
  top: -32px;
}

.bottomAddRowWrapper {
  bottom: 0;
}

.firstTopAddRowWrapper:hover img,
.restTopAddRowWrapper:hover img {
  color: var(--white);
  fill: var(--white);
  background-color: var(--brand-3);
}

.rotate90 {
  transform: rotate(90deg);
}

.rowWrapper:hover .restTopAddRowWrapper,
.rowWrapper:hover .firstTopAddRowWrapper,
.rowWrapper:hover .bottomAddRowWrapper {
  opacity: 1;
  cursor: pointer;
}
