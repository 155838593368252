.wrapper,
.elementDetails {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.wrapper {
  margin: 84px auto 0;
  padding: 0 calc(var(--pb-gutter-basic) * 3);
  width: 753px;
}

.templateWrapper {
  width: 753px;
  position: relative;
  left: -24px;
  transition: opacity 0.3s;
  font-family: var(--primaryFont);
  margin-bottom: calc(var(--pb-gutter-basic) * 4);
}

.hoverHeading {
  display: none;
  position: absolute;
  top: -20px;
  left: 0;
  font-size: var(--fs-s);
  color: var(--brand-3);
}

.template {
  padding: calc(var(--pb-gutter-basic) * 2) calc(var(--pb-gutter-basic) * 3);
}

.buttonGroup {
  position: absolute;
  right: 0;
  opacity: 0;
  top: 0;
  bottom: 0;
  z-index: var(--z800);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  transition: opacity 0.3s;
}

.buttonGroup svg {
  position: relative;
  top: 2px;
}

.buttonGroup button {
  margin-right: var(--pb-gutter-basic);
}

.templateWrapper:hover:after {
  position: absolute;
  left: 0;
  width: 100%;
  top: 0;
  content: '';
  height: 100%;
  background: var(--slighty-darker-blue);
  opacity: 0.2;
  z-index: var(--z600);
}

.templateWrapper:hover .buttonGroup {
  opacity: 1;
}

.templateWrapper:hover .hoverHeading {
  display: block;
}

.title {
  font-size: var(--fs-xxl);
  font-weight: var(--fw-bold);
  margin-bottom: calc(var(--pb-gutter-basic) * 2);
}

.description {
  font-size: var(--fs-xl);
  margin-bottom: calc(var(--pb-gutter-basic) * 2);
}

.svgWrapper {
  display: flex;
}
.label {
  font-size: var(--fs-s);
  color: var(--mono-5);
  font-weight: var(--fw-bold);
  margin-bottom: calc(var(--pb-gutter-basic) * 2);
}
.svgIcon {
  margin-right: calc(var(--pb-gutter-basic) * 2);
  border: 1px solid #ececec;
  padding: var(--pb-gutter-basic) calc(var(--pb-gutter-basic) * 2);
}
.selectedIcon {
  margin-right: calc(var(--pb-gutter-basic) * 2);
  border: 1px solid var(--brand-3);
  padding: var(--pb-gutter-basic) calc(var(--pb-gutter-basic) * 2);
}
.svgWrapper img {
  width: 24px;
  height: 24px;
}

.checkboxWrapper {
  margin-bottom: 16px;
}
